<template>
    <div>
        <div @click="popupActive=true" class="cursor-pointer">
            <div v-if="isLabelOkay" class="con-select" style="text-align:left">
                <label class="vs-select--label">{{label}}</label>
            </div>
            <div style="text-align:left">
                    <a radius class="a-icon bg-light p-1" href.prevent>
                    <span >{{selectedProductBez}}</span>
                    <vs-icon class="" icon="expand_more"></vs-icon>
                </a>
            </div>
        </div>

    <!-- z-index: mutter dlg hat 30000 - also muss dieser höher sein -->
        <vs-prompt
        @cancel="Canceled"
        @close="Canceled"
        :title="$t('PRODUCTPICKER.TTITLE')"
        :accept-text="$t('COM.SELECT')"
        :cancel-text="$t('COM.CANCEL')"
        id="promptProductpicker"
        style="z-index:30002"
        :active.sync="popupActive">
            <div style="max-width:1000px">
                {{$t('PRODUCTPICKER.SELECTED')+': '}}{{selectedProd.bez}}
                <vs-divider position="center">{{$t('PRODUCTPICKER.CHOSEN')}}</vs-divider>
                <vs-tabs >
                    <template v-for="(pricelist, pr_index) in allData.pricelists">
                        <vs-tab :key="'pricelists_'+pr_index" :label="pricelist.pricelist.bez">
                            <vs-tabs >
                                <template v-for="(cat, index) in pricelist.categories" >
                                    <vs-tab :key="'prodcat'+index" :label="cat.category.name">
                                        <div id="productpicker_table" class="vs-con-loading__container" >
                                            <vs-table
                                                search
                                                :data="cat.products"
                                                :noDataText="$t('PRODUCTPICKER.NO_DATA')"
                                                stripe
                                                max-items="15" 
                                                pagination
                                                hoverFlat
                                                v-model="selectedProd"
                                                class="productpickerTableClass">

                                                <template slot="thead">                               
                                                <vs-th sort-key="bez">
                                                    {{$t('PRODUCTPICKER.TABLE.COL1')}}
                                                </vs-th>
                                                <vs-th sort-key="article_nr">
                                                    {{$t('PRODUCTPICKER.TABLE.COL2')}}
                                                </vs-th>                                                                                                                                                                                                
                                                </template>

                                                <template slot-scope="{data}">
                                                <vs-tr :data="tr"  :key="'table_cat_'+indextr" v-for="(tr, indextr) in data" :state="selected == data[indextr].id?'primary':null">                    
                                                    <vs-td :data="data[indextr].bez">
                                                    {{data[indextr].bez}}
                                                    </vs-td>      
                                                    <vs-td :data="data[indextr].article_nr">
                                                    {{data[indextr].article_nr}}
                                                    </vs-td>                                                                                                                                                                                                                              
                                                </vs-tr>
                                                </template>
                                            </vs-table>
                                        </div> 
                                    </vs-tab>
                                </template>
                                
                            </vs-tabs>
                        </vs-tab>
                    </template>
                </vs-tabs>
                    
            </div>
        </vs-prompt>
    </div>
</template>

<script>
import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';

export default {
  name: "ProductPicker",
     components: {     
  },
  props:['value','disabled','label','isLabelOkay','pricesLastYear','country_fk', 'currency_fk','withMotifpickerOnly', 'headquateronly' ],
  data: function (){
     return {
       allData:{pricelists:[{pricelist:{bez:""},categories:[]}]},
       selected:this.value,
       selectedProd:{},
       popupActive:false,
       selectedProdOld:{}
     }
  },
  mounted(){

        var headonly = false;
        if(this.IsNotNull(this.headquateronly))
        {
            headonly = this.headquateronly;
        }
        var motifonly = false;
        if(this.IsNotNull(this.withMotifpickerOnly))
        {
            motifonly = this.withMotifpickerOnly;
        }

        var data = {lastYear:this.pricesLastYear,country_fk:this.country_fk,currency_fk:this.currency_fk,withMotifpickerOnly:motifonly,headquateronly:headonly};
        this.$store.dispatch('products/getProductsByCategory',data)
        .then(response => {
                if(this.IsNotNull(response))
                {
                    if(response.pricelists.length > 0)
                    {
                    this.SetSelectedProd(this.selected);
                    }
                }
                }, 
                error =>{
                this.selectedProd = {id:0,bez:error};
                });
  },
  computed:{
        productsByCategory(){
            var retVal = [];
            if(typeof(this.$store.state.products) != 'undefined')
            {
                if(this.$store.state.products.productsByCategory.data != null)
                {
                    retVal= this.$store.state.products.productsByCategory.data;
                }
            }
            return retVal;
        },
        selectedProductBez()
        {
            var ret = "";
            if(this.IsNotNull(this.selectedProd))
            {
                ret = this.selectedProd.bez +' ('+ this.selectedProd.article_nr + ')';
            }
            return ret;
        }
  },
  watch:{
    productsByCategory(value)
    {
        this.allData = value;
    },
    selected(value)
    {
        this.$emit('input', value);
    },
    selectedProd(value)
    {
        this.selected = value.id;
    }
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    Canceled(){
        this.SetSelectedProd(this.selectedProdOld.id);
    },
    SetSelectedProd(id)
    {
        this.selectedProd = {bez:"nix"};
        for(var i = 0; i < this.allData.pricelists.length; i++)
        {
            for(var j = 0; j < this.allData.pricelists[i].categories.length; j++)
            {
                for(var k = 0; k < this.allData.pricelists[i].categories[j].products.length; k++)
                {
                    if(this.allData.pricelists[i].categories[j].products[k].id == id)
                    {
                        this.selectedProd = this.allData.pricelists[i].categories[j].products[k];
                        this.selectedProdOld = this.allData.pricelists[i].categories[j].products[k];
                        break;
                    }
                }
            }
        }
    }
  }
};
</script>
<style>
div#promptProductpicker > div.vs-dialog{
  max-width: 700px !important;
}
div.productpickerTableClass > div > div > table > tr > td 
{
  padding-top:4px !important;
  padding-bottom:4px !important;
}
div#productpicker_table > div > header > div.vs-table--search 
{
    margin: 5px 5px 5px auto !important;
}
</style>